.a1
{
    width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100";
}
.a2
{
    width: 20%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100";
}
.a3
{
    width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100";
}
.a4
{
    width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100";
}
.a5
{
    width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100";
}
.a6
{
    width: 25rem;
}
.a7
{
    width: 60%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100";
}

  
  