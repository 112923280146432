/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.toggled {
  overflow: visible;
  width: 6.5rem!important;
}
.toggled1 {
  width: 0!important;
  overflow: hidden;
}

/* Add this CSS to your existing styles or create a new CSS file */
.toggle {
  width: 55px;
  height: 25px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.ball {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

.toggle.active .ball {
  left: 5px;
}

.toggle.inactive .ball {
  right: 5px;
}