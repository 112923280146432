/* login.css */

body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4; /* Light gray background color */
  }
  
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4; /* Light gray background color */
  }
  
  .login-paper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    background-color: #ffffff; /* White background color for the card */
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Light box shadow */
    width: 350px;
  }
  
  .login-form {
    width: 100%;
    margin-top: 16px;
  }
  
  .login-button {
    margin: 24px 0 16px;
    width: 100%;
    background-color: #007bff; /* Primary blue color for the button */
    color: #fff; /* White text color */
  }
  
  .login-button:hover {
    background-color: #0056b3; /* Darker blue color on hover */
  }
  
  .signup-link {
    cursor: pointer;
    color: #007bff;
    text-decoration: none;
  }
  
  .signup-link:hover {
    text-decoration: underline;
  }
  .google_btn {
    width: 230px;
    height: 40px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: white;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 20px 30px;
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .separator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }
  
  .separator-text {
    padding: 0 10px;
    color: #555;
  }
  .google_btn>img {
    width: 27px;
    height: 27px;
    object-fit: cover;
  }
  