.a1
{
    width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100";
}
.a2
{
    width: 20%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100";
}
.a3
{
    width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100";
}
.a4
{
    width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100";
}
.a5
{
    width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100";
}
.a6
{
    width: 25rem;
}
.a7
{
    width: 60%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100";
}
.toggled {
    overflow: visible;
    width: 6.5rem!important;
}
.toggled1 {
    width: 0!important;
    overflow: hidden;
}
/* Define scrollbar styles */
/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light gray */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Gray */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Dark gray */
  }
  